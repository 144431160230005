.curriculum-title {
    background-color: transparent;
    padding: 36px 24px 24px;
    background-image: linear-gradient(96.62deg, #e0257e 25%, #8217ee);
    background-position: 0 0;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    position: relative; /* Ensure positioning for absolute children */
  }
  
  .curriculum-title.pm {
    -webkit-text-fill-color: inherit;
    background-color: transparent;
    background-image: linear-gradient(#2e6540, #000);
    background-clip: border-box;
    border: 1px solid #000;
    flex-wrap: nowrap;
  }
  
  .curriculum-title.pm.change-grad.ai {
    background-image: linear-gradient(#541095, #000);
  }
  
  .curriculum-title-gradient {
    /* background-image: linear-gradient(96.62deg, #e0257e 25%, #8217ee); */
  }

  .accordion-button-custom {
    color: white;
    border: none;
    /* background-color: #541095; */
    /* background-image: linear-gradient(#541095, #000); */
    /* border: 1px solid #541095;  */
    padding: 24px 24px;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    position: relative; /* Ensure positioning for absolute children */
    justify-content: space-between; /* Space out the contents */
  }
  
  .accordion-button-custom:not(.collapsed) {
    color: white;
    border-radius: 10px;
  }

  .accordion-button-custom[aria-expanded='true'] {
    color: white;
    border-radius: 10px 10px 0 0;
  }
  
  .accordion-item-custom {
    border: none;
    margin-bottom: 30px; /* Add margin between accordion items */
  }
  
  .accordion-body-custom {
    color: azure;
    /* background-image: linear-gradient(135deg, rgba(18, 39, 25, .3), #54109580); */
    padding: 16px 24px;
    font-size: 16px;
    /* border: 1px solid #541095; */
    border-radius: 0 0 10px 10px;
    overflow: hidden;
  }

  .accordion-body-custom h1,
.accordion-body-custom h2,
.accordion-body-custom h3,
.accordion-body-custom h4,
.accordion-body-custom h5,
.accordion-body-custom h6 {
    color: #fff;
}

.accordion-body-custom p,
.accordion-body-custom a,
.accordion-body-custom span,
.accordion-body-custom li,
.accordion-body-custom ul,
.accordion-body-custom ol,
.accordion-body-custom div {
    color: #c9c9c9;
}

.accordion-body-custom-light h1,
.accordion-body-custom-light h2,
.accordion-body-custom-light h3,
.accordion-body-custom-light h4,
.accordion-body-custom-light h5,
.accordion-body-custom-light h6 {
    color: #000;
}

.accordion-body-custom-light p,
.accordion-body-custom-light a,
.accordion-body-custom-light span,
.accordion-body-custom-light li,
.accordion-body-custom-light ul,
.accordion-body-custom-light ol,
.accordion-body-custom-light div {
    color: #222;
}

  .accordion__panel--hidden {
    max-height: 0; /* Ensure the hidden panel has no height */
    padding: 0 24px; /* Reduce padding when collapsed */
  }

  .module-text {
    text-transform: uppercase;
    /* background-image: linear-gradient(135deg, #000, #021840); */
    /* border: 0.5px solid #fff; */
    border-radius: 12px 12px 12px 0;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    top: -22px;
    left: 0;
  }
  
  .module-text.pm.ai {
    /* background-image: linear-gradient(#000, #541095); */
    /* border-color: #541095; */
  }

  .arrow {
    display: inline-block;
    width: 10px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    padding: 5px;
    transform: rotate(45deg);
    transition: transform 0.3s ease; /* Transition for rotation */
  }
  
  .arrow.open {
    transform: rotate(225deg); /* Rotate when the accordion is open */
  }
  
  .arrow-container {
    display: flex;
    align-items: center;
    padding-left: 10px; /* Adjust padding as needed */
  }