/* StatsComponent.module.css */
.customRow {
    @media (max-width: 600px) {
      .col-sm-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  
    @media (min-width: 600px) and (max-width: 1000px) {
      .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  
    @media (min-width: 1000px) {
      .col-lg-4 {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
      }
    }
  }
  
  .customCard {
    position: relative;
    background: linear-gradient(to bottom right, #21252a, #093c00);
    padding: 20px;
    border-radius: 12px;
    overflow: hidden;
    color: white;
    transition: transform 0.3s ease-in-out;
  }

  .customCardWhite {
    background: #e5ffe0;
    box-shadow: 0px 4px 8px rgba(87, 87, 87, 0.1);
  }

  .customCardWhite:hover {
    background: #d1ffc8;
    box-shadow: 0px 4px 8px rgba(87, 87, 87, 0.1);
  }

  .customCard:hover {
    transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    background: linear-gradient(to bottom right, #21252a00, #42ff2220);
    border: 1px solid #42ff22;
  }

  .customCard.decline {
    position: relative;
    background: linear-gradient(to bottom right, #21252a, #3c0000);
    padding: 20px;
    border-radius: 12px;
    overflow: hidden;
  color: white;
  }


  .imageOverlay {
    position: absolute;
    right: 0px; /* Adjust the margin from the right */
    bottom: 10px; /* Adjust the margin from the bottom */
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    opacity: 0.2; /* Optional: Adjust the opacity */
    pointer-events: none; /* Prevent the div from blocking interactions */
  }
  
  .customHeading {
    color: #217c12;
    text-align: center;
    /* -webkit-text-fill-color: transparent; */
    /* background-image: linear-gradient(96.62deg, #e0257e, #8217ee); */
    /* -webkit-background-clip: text; */
    background-clip: text;
    font-weight: 700;
    font-size: 20px;
  }
  