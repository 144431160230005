.containerLarge2 {
    width: 100%;
    max-width: 68.5rem;
    margin-left: auto;
    margin-right: auto;
}

.meetContentWrapper {
    grid-column-gap: 34px;
    grid-row-gap: 34px;
    grid-template-rows: auto;
    grid-template-columns: 0.75fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        display: block;
    }
}

.content-dark-theme {
    color: #010101;
}

.meetContent {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    flex-direction: column;
    display: flex;
}

.mentorOpeningContent {
    color: #f4f4f4;
    position: relative;
}

.content-dark-theme .mentorOpeningContent {
    color: #171717;
}

.meetSubPara {
    color: #e5e5e5;
    font-size: 24px;
    line-height: 145%;
    display: block;
}

.content-dark-theme .meetSubPara {
    color: #272727;
}

.featuredAnimation {
    width: 100%;
    height: auto;
    padding: 1rem;
    position: relative;
    overflow: hidden;
}


.meetSlider {
    display: flex;
    animation: scroll 10s linear infinite;
    white-space: nowrap;
}

.meetSlider::before {
    content: "";
    display: inline-block;
    padding-right: 10px;
}

.sliderImg {
    width: 20rem;
    height: 12rem;
    margin-right: 30px;
    border-radius: 20px;
}

@keyframes scroll {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-180%);
    }
}

.meetMainImage {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}

.meetMainImage::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #00000000 75%, #000000 100%);
    border-radius: 1.5rem;
    /* Match the image border radius */
    pointer-events: none;
    /* Ensures it doesn't interfere with interaction */
}

.meetPara {
    color: #ffffff;
    /* font-family: 'Inter', sans-serif; */
    font-size: 18px;
    line-height: 1.45;
    display: block;
    margin-bottom: 1rem;
}

.content-dark-theme .meetPara {
    color: #111111;
}

.followersWrapper {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: absolute;
    bottom: 39px;
    left: 70px;
    right: auto;
}

.meetImage {
    width: 100%;
    height: auto;
    border-radius: 1.5rem;

    @media (min-width: 360px) {
        max-height: 500px;
        object-fit: cover;
    }

    @media (min-width: 660px) {
        max-height: 600px;
        object-fit: cover;
    }

    @media (min-width: 992px) {
        max-height: 800px;
        object-fit: cover;
    }
}



.hideDesktop {
    display: none;
}

@media (max-width: 991px) {
    .hideDesktop {
        display: block;
    }

    .hideMobile {
        display: none;
    }
}

.extraWidth {
    width: 100%;
}

.followers {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

.followersText {
    margin-left: 5px;
    color: gold;
    font-size: 20px;
    font-weight: 500;

    @media (min-width: 640px) {
        font-size: 24px;
        font-weight: 800;
    }
}

.goldenBg {
    background-color: gold;
}

.social_media_icons {
    color: gold;
    height: 27px;
    width: auto;
}