.container-xl-low-padding {
    width: 100%;
    max-width: 1152px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.banner-small {
    /* background-color: #000000; */
    margin-top: 3rem;
    padding-bottom: 30px;
}

.banner-dark-theme {
    margin-top: 3rem;
}

.text-white-color {
    -webkit-text-fill-color: transparent;
    background-color: #fff;
    -webkit-background-clip: text;
    background-clip: text
}

.text-black-color {
    -webkit-text-fill-color: transparent;
    background-color: #000000;
    -webkit-background-clip: text;
    background-clip: text
}

.text-gradient-color {
    color: #fff;
    text-align: center;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(96.62deg, var(--gradient-color1, #e0257e) 25%, var(--gradient-color2, #8217ee));
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: 800;
}

.text-dark-colors {
    color: #2a2a2a !important;
}

.text-dark-colors h1,
.text-dark-colors h2,
.text-dark-colors h3,
.text-dark-colors h4,
.text-dark-colors h5,
.text-dark-colors h6,
.text-dark-colors p,
.text-dark-colors li,
.text-dark-colors ul {
    color: #4a4a4a !important;
}

.responsive-text {
    font-size: 2.5rem;
    font-weight: 800;

    @media (max-width: 1200px) {
        font-size: 2rem;
    }

    @media (max-width: 992px) {
        font-size: 1.875rem;
    }

    @media (max-width: 768px) {
        font-size: 1.75rem;
    }

    @media (max-width: 576px) {
        font-size: 1.5rem;
    }
}

.subtitle-text {
    font-size: 1.5rem;

    font-weight: 400;
    color: #d4d4d4;
    text-align: left;

    @media (max-width: 1200px) {
        font-size: 1.375rem;
    }

    @media (max-width: 992px) {
        font-size: 1.25rem;
        line-height: 1.625rem;
    }

    @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.625rem;
    }
}

.subtitle-text h1,
.subtitle-text h2,
.subtitle-text h3,
.subtitle-text h4,
.subtitle-text h5,
.subtitle-text h6,
.subtitle-text p,
.subtitle-text li,
.subtitle-text ul {
    color: #d4d4d4;
}

/* stylesHero.module.css */
.subtitle-text ul {
    position: relative;
    padding-left: 2rem; /* Adjust as needed */
    margin-bottom: 0.5rem;
    list-style-type: none; /* Remove default bullet points */
  }
  
  .subtitle-text ul li {
    position: relative;
    padding-left: 2rem; /* Adjust as needed */
    margin-bottom: 0.5rem;
  }
  
  .subtitle-text ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem; /* Adjust as needed */
    height: 1.5rem; /* Adjust as needed */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="%239797f2" d="M12 0c-5.522 0-10 4.395-10 9.815 0 5.505 4.375 9.268 10 14.185 5.625-4.917 10-8.68 10-14.185 0-5.42-4.478-9.815-10-9.815zm1 17.932v-3.932h-2v3.932c-3.618-.453-6.479-3.315-6.931-6.932h3.931v-2h-3.931c.452-3.617 3.314-6.479 6.931-6.931v3.931h2v-3.931c3.617.452 6.479 3.314 6.931 6.931h-3.931v2h3.931c-.452 3.617-3.314 6.479-6.931 6.932z"/></svg>') no-repeat center center;
    background-size: contain;
  }
  
  /* Explicitly hide the marker */
  .subtitle-text ul li::marker {
    content: none;
  }
  
  /* Apply theme color */
  .subtitle-text ul li::before {
    filter: var(--theme-gradient1);
  }
  
  

.subtitle-max-width {
    max-width: 1152px;
}

.info-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #ccc;
    /* Assuming a light gray color */
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.info-item {
    display: flex;
    align-items: center;
    background-color: #2a2a2a;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    margin-right: 1rem;

    @media (max-width: 768px) {
        margin-right: 0.75rem;
        padding: 0.5rem 1rem 0.5rem 0.5rem;
    }

    @media (max-width: 576px) {
        margin-right: 0.5rem;
        padding: 0.25rem 1rem 0.25rem 0.25rem;
    }
}

.info-content {
    font-size: small;
    font-weight: 800;
}

.icon-container {
    background-color: #3a3a3a;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 0.5rem;
}

.heroicon {
    width: 16px;
    height: 16px;
    color: white;
}

.content-wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;

    @media (min-width: 920px) {
        column-gap: 4rem;
    }

    @media (min-width: 1080px) {
        column-gap: 8rem;
    }

    @media (max-width: 620px) {
        flex-wrap: wrap;
    }
}

.main-content {
    width: 75%;

    @media (max-width: 620px) {
        width: 100%;
    }
}

.banner-small-content-heading {
    font-size: 32px;
    font-weight: 800;
    color: #fff;

    @media (max-width: 767px) {
        font-size: 32px;
        font-weight: 800;
    }
}

.banner-dark-theme .banner-small-content-heading {
    color: #090909;
}

.banner-heading-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    display: flex;
    margin-bottom: 2rem;

    @media (min-width: 767px) {
        margin-bottom: 4rem;
    }
}

.banner-small-img {
    position: relative;
    padding: 30px 0;
    color: white;
  }
  
  .banner-small-img::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px; /* Set the desired width of the circle */
    height: 200px; /* Set the desired height of the circle */
    background-color: var(--banner-bg-color, #a58400);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    filter: blur(200px); /* Set the desired blur amount */
    z-index: 0;

    @media (max-width: 767px) {
        width: 100%; /* Set the desired width of the circle */
        height: 300px; /* Set the desired height of the circle */
        filter: blur(100px); /* Set the desired blur amount */
    }
  }