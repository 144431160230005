/* styles/Superpowers.module.css */
.superpowerContainer {
    max-width: 780px; /* Fixed width for larger screens */
    margin: 0 auto;
  }
  
  .superpowerText {
    padding-top: 10px;
    padding-bottom: 20px;
    color: #121212;
    font-weight: 100;
    font-size: 42px;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    
    @media (max-width: 767px) {
        font-size: 42px;
    }
  }

  .nonSuperpowerText {
    line-height: 1.3;
    font-weight: 500;
    font-size: 2.5rem;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: 767px) {
        font-size: 1.5rem;
    }
  }
  
  .endCursor::after {
    content: '|';
    color: #3e3ef800;
    animation: blink 1s step-end infinite;
  }
  
  .blinking::after {
    content: '|';
    animation: blink 1s step-end infinite;
  }
  
  @keyframes blink {
    from, to {
      visibility: hidden;
    }
    50% {
      visibility: visible;
    }
  }
  