.mentorItem {
    /* background-image: linear-gradient(123deg, #26262659, #8236ee50); */
    /* border: 1px solid #8236ee50; */
    border-radius: 16px;
    width: 250px;
    padding: 20px 16px;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    
    @media (max-width: 768px) {
        width: calc(150px + (100 * ((100vw - 360px) / 408)));
        padding: 12px 10px;
    }

    @media (max-width: 540px) {
        width: calc(130px + (70 * ((100vw - 360px) / 180)));
    }

    @media (min-width: 1100px) {
        width: 280px;
    }
  }

  .customRowMentors {
    row-gap: 0.5rem;
    column-gap: 0rem;
    @media (min-width: 768px){
        row-gap: 1rem;
        column-gap: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    .mentorWrapper {
        flex: 0 0 100% !important; 
        max-width: 100% !important;
    }

    .custom-row-mentors {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mentorItem {
        width: 90%;
        max-width: 400px;
    }
}

  .mentorBigItem {
    width: 360px;
    /* Dynamic width scaling between 300px to 400px */
    @media (min-width: 480px) and (max-width: 1200px) {
        width: calc(200px + (120 * ((100vw - 360px) / 840))); 
    }

    @media (max-width: 480px) {
        width: 100%;
    }
  }

  .mentorItem:hover {
    transform: translate3d(0px, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
  
  .mentorImage {
    border-radius: 14px;
    overflow: hidden;
    width: 100%;
    padding-bottom: 100%; /* 1:1 aspect ratio */
    position: relative;
  }
  
  .imageFitCover {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .mentorContainer {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-top: 20px;
    width: 100%;
  }
  
  .mentorContent {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .mentorName {
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    @media (max-width: 540px){
        font-size: 20px;
    }
  }

  .mentorpostName {
    color: #ccc;
    font-size: 16px;
    @media (max-width: 540px){
        font-size: 16px;
    }
  }
  
  .changeMcp {
    /* background-image: linear-gradient(143deg, rgba(36, 36, 36, 0.23), #8236ee50); */
    justify-content: center;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    @media (max-width: 540px){
        height: 40px;
    }
  }
  
  .companyIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px; /* Adjust the gap as needed */
  }
  
  .companyIcon {
    height: 20px;
    width: auto;
    @media (max-width: 540px){
        height: 12px;
    }
  }
  